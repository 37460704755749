<template>
  <div>
    <b-row class="align-items-center">
      <b-col
        cols="8"
        md="4"
      >
        <b-form-group label="Project">
          <v-select
            v-model="selectedProject"
            :width="500"
            :options="projects"
            :reduce="item => item.uuid"
            placeholder="Select Project"
            label="name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        md="8"
      >
        <b-button
          variant="primary"
          class="pull-right"
          @click="showModal = true"
        >
          Add Team
        </b-button>
      </b-col>
    </b-row>
    <create-update
      :show="showModal"
      @close="showModal = false"
      @onSave="getTeam"
    />
    <b-row class="pt-4">
      <b-col
        v-for="team of teams"
        :key="team.uuid"
        size="md-4"
        class="mt-3 col-md-4"
      >
        <team-card :value="team" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BButton, BFormGroup } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'
import CreateUpdate from './update.vue'
import TeamCard from './components/teamCard.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    CreateUpdate,
    vSelect,
    TeamCard,
  },
  data() {
    return {
      showModal: false,
      projects: [],
      selectedProject: this.$store.state.project.selectedProject,
      teams: [],
      showAddTeamModel: false,
    }
  },
  watch: {
    selectedProject: {
      handler(newValue) {
        if (newValue) {
          this.getTeam()
        }
      },
      immediate: true,
    },
  },
  created() {
    useApollo.project.getProjects({ first: -1 }).then(response => {
      this.projects = response.data.me.projects.data
    }).catch(error => {
      this.showErrorMessage(error)
    })
  },
  methods: {
    getTeam() {
      useApollo.team.getTeam({
        uuid: this.selectedProject,
      }).then(response => {
        this.teams = response.data.project.teams.data
      })
    },
  },
}
</script>
